export const GTMEvents = {
  carsharingBookingCreated: 'carsharing_booking_created',
  b2BCompanyCreated: 'b2b_company_created',
  userSubscribed: 'user_subscribed',
  b2BSignupAfterInvitation: 'b2b_signup_after_invitation',
  pageView: 'page_view',
  tfaVerificationCode: '2_fa_verification_code',
  tfaCodeSet: '2_fa_code_set',
  purchase: 'purchase',
  tfaResendCode: '2_fa_resend_code',
  viewItemList: 'view_item_list',
  selectItem: 'select_item',
  viewItem: 'view_item',
  addToCart: 'add_to_cart',
  removeFromCart: 'remove_from_cart',
  beginCheckout: 'begin_checkout',
  addPaymentInfo: 'add_payment_info',
  viewCart: 'view_cart',
};

export const GTMModalEvents = {
  addPaymentMethod: 'add-payment-method-modal',
  selectPaidTariffConfirmationModal: 'select-paid-tariff-confirmation-modal',
  selectPaidInsurancesConfirmationModal: 'select-paid-insurances-confirmation-modal',
  validationStatusModal: 'validation-status-modal',
  accountCreationConfirmationModal: 'account-creation-confirmation-modal',
  drivingLicenceValidationStep1: 'driving-licence-validation-step1',
  drivingLicenceValidationStep2: 'driving-licence-validation-step2',
  drivingLicenceValidationStep3: 'driving-licence-validation-step3',
  keycardViewInitialStep: 'keycard-view-initial-step',
  keycardViewLinkExisting: 'keycard-view-link-existing',
  newKeycardView: 'new-keycard-view',
  submitKeyCardConfiguration: 'submit-key-card-configuration',
  locationInfo: 'location-info',
  bookingCreated: 'booking-created',
  bookingSummaryInsurancesInfo: 'booking-summary-insurances-info',
  editBookingTime: 'edit-booking-time',
  editBookingInsurance: 'edit-booking-insurance',
  cancelBooking: 'cancel-booking',
  editBookingPeople: 'edit-booking-people',
  addCarpoolingToBooking: 'add-carpooling-to-booking',
  cancelBookingCarpooling: 'cancel-booking-carpooling',
  editEmployeeBookingTime: 'edit-employee-booking-time',
  editEmployeeBookingReturnLocation: 'edit-employee-booking-return-location',
  editEmployeeBookingPeople: 'edit-employee-booking-people',
  cancelEmployeeBooking: 'cancel-employee-booking',
  editEmployeePrebookingTime: 'edit-employee-prebooking-time',
  editEmployeePrebookingPeople: 'edit-employee-prebooking-people',
  editEmployeePrebookingReturnLocation: 'edit-employee-prebooking-return-location',
  cancelEmployeePrebooking: 'cancel-employee-prebooking',
  editEmployeeDrivingLicense: 'edit-employee-driving-license',
  editEmployeeBadge: 'edit-employee-badge',
  inviteEmployees: 'invite-employees',
  cancelAccount: 'cancel-account',
};

export const GTMEViewItemListventKeys = {
  itemId: 'item_id',
  itemName: 'item_name',
  itemCategory: 'item_category',
  itemCategory2: 'item_category2',
  itemCategory3: 'item_category3',
  itemBrand: 'item_brand',
  itemListId: 'item_list_id',
  itemListName: 'item_list_name',
  index: 'index',
  price: 'price',
};
